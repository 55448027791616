import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subadminlog',
  templateUrl: './subadminlog.component.html',
  styleUrls: ['./subadminlog.component.css']
})
export class SubadminlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
