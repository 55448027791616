<head>
</head>

<section class="user-list">
    <div class="heading">
        <h2 style="font-family: cursive;">USER LIST</h2>
    </div>
    <div class="btn-toolbar">
        <button class="btn">New User</button>
        <button class="btn">Import</button>
        <button class="btn">Export</button>
    </div>

    <div class="well">
        <table class="table">
            <thead>
                <tr>
                    <th>S.No:</th>
                    <th>User Name</th>
                    <th>Mobile Number</th>
                    <th>Plan Type</th>
                    <th>Edit Option</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>Ram</td>
                    <td>9988776655</td>
                    <td>Dummy plan</td>
                    <td>
                        <a href="user.html"><i class="fa fa-pencil"></i></a>
                        <a href="#myModal" role="button" data-toggle="modal"><i class="fa fa-trash-o"></i></a>
                    </td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Rani</td>
                    <td>9988776655</td>
                    <td>Dummy plan</td>
                    <td>
                        <a href="user.html"><i class="fa fa-pencil"></i></a>
                        <a href="#myModal" role="button" data-toggle="modal"><i class="fa fa-trash-o"></i></a>
                    </td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Raja</td>
                    <td>9988776655</td>
                    <td>Dummy plan</td>
                    <td>
                        <a href="user.html"><i class="fa fa-pencil"></i></a>
                        <a href="#myModal" role="button" data-toggle="modal"><i class="fa fa-trash-o"></i></a>
                    </td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Ravi</td>
                    <td>9988776655</td>
                    <td>Dummy plan</td>
                    <td>
                        <a href="user.html"><i class="fa fa-pencil"></i></a>
                        <a href="#myModal" role="button" data-toggle="modal"><i class="fa fa-trash-o"></i></a>
                    </td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Rekha</td>
                    <td>9988776655</td>
                    <td>Dummy plan</td>
                    <td>
                        <a href="user.html"><i class="fa fa-pencil"></i></a>
                        <a href="#myModal" role="button" data-toggle="modal"><i class="fa fa-trash-o"></i></a>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <div class="pagination">
        <ul>
            <li><a href="#">Prev</a></li>
            <li><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li><a href="#">Next</a></li>
        </ul>
    </div>
    <div class="modal small hide fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            <h3 id="myModalLabel">Delete Confirmation</h3>
        </div>
        <div class="modal-body">
            <p class="error-text">Are you sure you want to delete the user?</p>
        </div>
        <div class="modal-footer">
            <button class="btn" data-dismiss="modal" aria-hidden="true">Cancel</button>
            <button class="btn btn-danger" data-dismiss="modal">Delete</button>
        </div>
    </div>
</section>