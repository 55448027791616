import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepg',
  templateUrl: './homepg.component.html',
  styleUrls: ['./homepg.component.css']
})
export class HomepgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
