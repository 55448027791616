<div class="form_wrapper">
    <div class="form_container">
        <div class="title_container">
            <h2 style="font-family: cursive;">Sign Up Form</h2>
        </div>
        <div class="row clearfix">
            <div class="">
                <form>

                    <div class="row clearfix">
                        <div class="col_half">
                            <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                                <input type="text" name="name" placeholder="First Name *" />
                            </div>
                        </div>
                        <div class="col_half">
                            <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                                <input type="text" name="name" placeholder="Last Name *" required />
                            </div>
                        </div>
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-envelope"></i></span>
                        <input type="email" name="email" placeholder="Email *" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                        <input type="password" name="password" placeholder="Password *" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                        <input type="password" name="password" placeholder="Re-type Password *" required />
                    </div>
                    <!-- <div class="input_field radio_option">
                        <input type="radio" name="radiogroup1" id="rd1">
                        <label for="rd1">ADMIN</label>
                        <input type="radio" name="radiogroup1" id="rd2">
                        <label for="rd2">SUB-ADMIN</label>
                    </div> -->
                    <div class="input_field select_option">
                        <select>
                            <option>Select Type *</option>
                            <option>Admin</option>
                            <option>Sub-Admin</option>
                        </select>
                        <div class="select_arrow"></div>
                    </div>
                    <!-- <div class="input_field checkbox_option">
                        <input type="checkbox" id="cb1">
                        <label for="cb1">I agree with terms and conditions</label>
                    </div>
                    <div class="input_field checkbox_option">
                        <input type="checkbox" id="cb2">
                        <label for="cb2">I want to receive the newsletter</label>
                    </div> -->
                    <input class="button" type="submit" value="REGISTER" />
                </form>
            </div>
        </div>
    </div>
</div>