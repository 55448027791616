<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">SCHEDULER ADMIN</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/" style="color: white;"><i class="fa fa-home"></i> Home <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/settings-component"><i class="fa fa-cogs"></i> Setting</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/signup-component" routerLinkActive="active"><i class="fa fa-user-plus" aria-hidden="true"></i> SignUp</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false"><i class="fa fa-sign-in"></i> Login</a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a  routerLink="/adminlog-component" routerLinkActive="active" class="dropdown-item"> <i class='fas fa-user-alt'></i> Admin</a>
          <a routerLink="/subadminlog-component" routerLinkActive="active" class="dropdown-item" > <i class='fas fa-user-friends'></i> Sub-Admin</a>

        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- -------SIDENAV-------- -->

<div id="wrapper">
  <div id="sidebar-wrapper">
      <aside id="sidebar">
          <ul id="sidemenu" class="sidebar-nav">
              <li>
                  <a routerLink="/dashboard-component" routerLinkActive="active">
                      <span class="sidebar-icon"><i class="fa fa-dashboard"></i></span>
                      <span class="sidebar-title">Dashboard</span>
                  </a>
              </li>
              <li>
                  <a class="accordion-toggle collapsed toggle-switch" data-toggle="collapse" href="#submenu-2">
                      <span class="sidebar-icon"><i class="fa fa-users"></i></span>
                      <span class="sidebar-title">Management </span>
                      <i class="fa fa-caret-down"></i>
                  </a>
                  <ul id="submenu-2" class="panel-collapse collapse panel-switch" role="menu">
                      <li><a routerLink="/userlist-component" routerLinkActive="active"><i class="fa fa-caret-right"></i>Users</a></li>
                      <li><a routerLink="/payment-component" routerLinkActive="active"><i class="fa fa-caret-right"></i>Payment Details</a></li>
                  </ul>
              </li>
              <li>
                  <a class="accordion-toggle collapsed toggle-switch" data-toggle="collapse" href="#submenu-3">
                      <span class="sidebar-icon"><i class="fa fa-newspaper-o"></i></span>
                      <span class="sidebar-title">Blog </span>
                      <i class="fa fa-caret-down"></i>
                     
                  </a>
                  <ul id="submenu-3" class="panel-collapse collapse panel-switch" role="menu">
                      <li><a href="#"><i class="fa fa-caret-right"></i>Posts</a></li>
                      <li><a href="#"><i class="fa fa-caret-right"></i>Comments</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#">
                      <span class="sidebar-icon"><i class="fa fa-database"></i></span>
                      <span class="sidebar-title">User Data</span>
                  </a>
              </li>
              <li>
                  <a href="#">
                      <span class="sidebar-icon"><i class="fa fa-shopping-basket"></i></span>
                      <span class="sidebar-title">Resources</span>
                  </a>
              </li>
          </ul>
      </aside>            
  </div>
  
</div>

<!-- -------welcome------ -->

<router-outlet></router-outlet>
<!-- <app-homepg></app-homepg> -->