<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-heading">
            <h2 class="text-center"><i class='fas fa-user-alt'></i>  ADMIN LOGIN</h2>
        </div>
        <hr class="hr">
        <div class="modal-body">
            <form action="" role="form">
                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <span class="glyphicon glyphicon-user"></span>
                        </span>
                        <input type="text" class="form-control" placeholder="User Name" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <span class="glyphicon glyphicon-lock"></span>
                        </span>
                        <input type="password" class="form-control" placeholder="Password" />

                    </div>

                </div>

                <div class="form-group text-center">
                    <button type="submit" class="btn btn-success btn-lg">Login</button>
                    <a href="#" class="btn btn-link">forget Password</a>
                </div>

            </form>
        </div>
    </div>
</div>