import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminlogComponent } from './adminlog/adminlog.component';
import { SubadminlogComponent } from './subadminlog/subadminlog.component';
import { HomepgComponent } from './homepg/homepg.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserlistComponent } from './userlist/userlist.component';
import { PaymentComponent } from './payment/payment.component';
import { SettingsComponent }from './settings/settings.component';

  

const routes: Routes = [
  { path:'', component: HomepgComponent },
  { path: 'adminlog-component', component: AdminlogComponent },
  { path:'subadminlog-component', component: SubadminlogComponent },
  { path:'signup-component', component: SignupComponent },
  { path:'dashboard-component', component: DashboardComponent },
  { path: 'userlist-component', component: UserlistComponent },
  { path: 'payment-component', component: PaymentComponent },
  { path: 'settings-component', component: SettingsComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
