<h2 class="dash-text">DASHBOARD</h2>

<section class="dash-section">
    <div class="dash-item">
        <div class="dash-title">
            New Clients
        </div>
        <div class="dash-content">
            540
        </div>
        <div class="dash-measure"></div>
        <div class="dash-details"></div>
    </div>

    <div class="dash-item homes">
        <div class="dash-title">
            New Deals
        </div>
        <div class="dash-content">
            45
        </div>
        <div class="dash-measure"></div>
        <div class="dash-details"></div>
    </div>

    <div class="dash-item parks">
        <div class="dash-title">
            Total Sales
        </div>
        <div class="dash-content">
            $250,000,000
        </div>
        <div class="dash-measure"></div>
        <div class="dash-details"></div>
    </div>

    <div class="dash-item revenue">
        <div class="dash-title">
            Total Income
        </div>
        <div class="dash-content">
            $500,000,000
        </div>
        <div class="dash-measure"></div>
        <div class="dash-details">
        </div>
    </div>
</section>